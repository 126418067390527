import React, { useState, useEffect } from "react";

import axios from "axios";

const Gateway = () => {
  const [RazorPayout, setRazorpayout] = useState(true);
  const [KvmIsActive, setKvmIsActive] = useState(true);
  const [RazorpayAuto, setRazorpayAuto] = useState(false);
  const [RazorPayKey, setRazorpayKey] = useState();
  const [RazorPaySecretKey, setRazorpaysecretKey] = useState();
  const [AccountName, setAccountName] = useState();
  const [decentroPayout, setdecentropayout] = useState(true);
  const [decentroDeposit, setdecentroDeposit] = useState(true);
  const [decentroAuto, setdecentroAuto] = useState(false);
  const [IndiPayIsActive, setIndiPayIsActive] = useState(false);
  const [IndiPayPayoutIsActive, setIndiPayPayoutIsActive] = useState(false);
  const [settingId, setSettingId] = useState("");
  const [kvmActiveGateway, setKvmActiveGateway] = useState(1);
  const [kvmActivePayoutGateway, setKvmActivePayoutGateway] = useState(1);
  const [isManualPayoutActive, setisManualPayoutActive] = useState(false);


  const [referralUsers, setreferralUsers] = useState([]);


  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  useEffect(() => {


    const data = axios.get(baseUrl + "gatewaysettings/data", {}).then((res) => {
      console.log(res.data);
      setSettingId((res.data._id) ? res.data._id : '');
      setRazorpayout(res.data.RazorPayout || false);
      setKvmIsActive(res.data.KvmIsActive || false);
      setRazorpayAuto(res.data.RazorpayAuto || false);
      setdecentropayout(res.data.decentroPayout || false);
      setdecentroDeposit(res.data.decentroDeposit || false);
      setdecentroAuto(res.data.decentroAuto || false);
      setIndiPayIsActive(res.data.IndiPayIsActive || false);
      setIndiPayPayoutIsActive(res.data.IndiPayPayoutIsActive || false);
      setRazorpayKey(res.data.RazorPayKey || '');
      setRazorpaysecretKey(res.data.RazorPaySecretKey || '');
      setAccountName(res.data.AccountName || '');
      setKvmActiveGateway(res.data.kvmActiveGateway || 1);
      setKvmActivePayoutGateway(res.data.kvmActivePayoutGateway || 1);
      setisManualPayoutActive(res.data.setisManualPayoutActive || false);

    });

  }, []);

  const handleSubmit1 = async (e) => {
    e.preventDefault();

    const response = await axios.post(
      baseUrl + `gatewaysettings`,
      {
        settingId,
        RazorPayout,
        KvmIsActive,
        RazorpayAuto,
        decentroPayout,
        decentroDeposit,
        decentroAuto,
        IndiPayIsActive,
        IndiPayPayoutIsActive,
        RazorPayKey,
        RazorPaySecretKey,
        AccountName,
        kvmActiveGateway,
        kvmActivePayoutGateway,
        isManualPayoutActive
      }
    );
    console.log(response.data.status);
    if (response.data.status === 'success') {
      alert("Settings submitted successfully");
    } else {
      alert("Settings Not Submitted");
    }
  };

  useEffect(() => {
    const getReferlUsers = async () => {
      console.log("-getReferlUsers")
      try {
        const access_token = localStorage.getItem('token');
        const headers = {
          Authorization: `Bearer ${access_token}`
        };
        const result = await axios.get(baseUrl + 'user/top-referal-users', { headers });
        console.log("------result", result.data); // Use result.data to access the actual data

        // Process the result or set state here
        setreferralUsers(result.data.data); // Uncomment this when you have state to update
      } catch (error) {
        console.error("Error fetching referral users:", error);
        // Handle errors as needed
      }
    };
    getReferlUsers(); // Call the function inside useEffect

  }, []);

  const submitReferal = async (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const data = {
      fromReferalPhone: formData.get('fromReferalPhone'),
      toReferalPhone: formData.get('toReferalPhone'),
    };

    // const fromReferalPhone = e.target.fromReferalPhone.value;
    // const toReferalPhone = e.target.Phone.value;

    try {
      const access_token = localStorage.getItem('token')
      const headers = {
        Authorization: `Bearer ${access_token}`
      }
      const response = await axios.post(baseUrl + `user/transfer-referal`, data, { headers });

      console.log(response.data.status);
      if (response.data.status === 'success') {
        alert(response.data.message);
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error("There was an error submitting the form!", error);
      alert("There was an error submitting the form!");
    }
  };

  return (
    <>
      <h4 className="text-uppercase font-weight-bold my-3 text-white">
        Payment Gateway Settings
      </h4>

      {/* <h5 className="text-uppercase font-weight-bold my-3 text-white">KVM</h5> */}

      <form
        action="gatewaysettings"
        className="form"
        onSubmit={handleSubmit1}
        method="patch"
        encType="multipart/form-date"
      >
        <div className="form-row">
          <div className="form-group col-md-4">
            <label htmlFor="isManualPayoutActive">Withdrawal On/Off</label>
            
            <select
              className="form-control"
              name="isManualPayoutActive"
              id="isManualPayoutActive" 
              value={isManualPayoutActive}
              onChange={(e) => setisManualPayoutActive(e.target.value)}
            >
              <option value="true">on</option>
              <option value="false">off</option>
            </select>
          </div>
        </div>
        {/* <div className="form-row">

          <div className="form-group col-md-4">
            <label htmlFor="buttonrazdep" className=" my-1">
              KVM Gateway
            </label>
            <select
              className="form-control "
              name="kvmActiveGateway"
              id="kvmActiveGateway"
              value={kvmActiveGateway}
              onChange={(e) => setKvmActiveGateway(e.target.value)}
            >
              <option value="1">KVM - Shankar</option>
            </select>
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="buttonrazdep" className=" my-1">
              KVM Deposit - Add Money
            </label>
            <select
              className="form-control "
              name="KvmIsActive"
              id="KvmIsActive"
              value={KvmIsActive}
              onChange={(e) => setKvmIsActive(e.target.value)}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>
          </div>

        </div>
        <div className="form-row">
          <div className="form-group col-md-4">
            <label htmlFor="buttonrazdep" className=" my-1">
              KVM Payout -(Active Payout Account)
            </label>
            <select
              className="form-control "
              name="kvmActivePayoutGateway"
              id="kvmActivePayoutGateway"
              value={kvmActivePayoutGateway}
              onChange={(e) => setKvmActivePayoutGateway(e.target.value)}
            >
              <option value="1">KVM - Shankar</option>
            </select>
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="buttonrazpay" className="my-1">
              KVM Payout - Withdrawal Money
            </label>
            <select
              className="form-control "
              name=""
              id=""
              value={RazorPayout}
              onChange={(e) => setRazorpayout(e.target.value)}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="buttonrazauto" className=" my-1">
              KVM Auto
            </label>
            <select
              className="form-control"
              name=""
              id=""
              value={RazorpayAuto}
              onChange={(e) => setRazorpayAuto(e.target.value)}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>
          </div>
        </div> */}
        <div>&nbsp;</div>
        <div>&nbsp;</div>
        <div className="form-row">
          <div className="form-group col-md-4">
            <button type="submit" className="btn btn-danger">
              Submit
            </button>
          </div>
        </div>
      </form>



    </>
  );
};

export default Gateway;
